import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{color: "#A9A9A9"}}>
          I'm still working on this.<br/><div style={{color: "#9370DB"}}>Come back when I'm done!</div>
        </p>
      </header>
    </div>
  );
}

export default App;
